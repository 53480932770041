<template>
  <div>


    <div class="wrapper">

      <!-- Navbar -->
      <nav class="main-header navbar navbar-expand navbar-white navbar-light">
        <!-- Left navbar links -->
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
          </li>
          <li class="nav-item d-none d-sm-inline-block">
            <a href="index3.html" class="nav-link">Home</a>
          </li>
          <li class="nav-item d-none d-sm-inline-block">
            <a href="#" class="nav-link">Contact</a>
          </li>
        </ul>
    
        <!-- Right navbar links -->
        <ul class="navbar-nav ml-auto">
          <!-- Navbar Search -->
          <li class="nav-item">
            <a class="nav-link" data-widget="navbar-search" href="#" role="button">
              <i class="fas fa-search"></i>
            </a>
            <div class="navbar-search-block">
              <form class="form-inline">
                <div class="input-group input-group-sm">
                  <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search">
                  <div class="input-group-append">
                    <button class="btn btn-navbar" type="submit">
                      <i class="fas fa-search"></i>
                    </button>
                    <button class="btn btn-navbar" type="button" data-widget="navbar-search">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </li>
    
          <!-- Messages Dropdown Menu -->
          <li class="nav-item dropdown">
            <a class="nav-link" data-toggle="dropdown" href="#">
              <i class="far fa-comments"></i>
              <span class="badge badge-danger navbar-badge">3</span>
            </a>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <a href="#" class="dropdown-item">
                <!-- Message Start -->
                <div class="media">
                  <img src="/web/dist/img/user1-128x128.jpg" alt="User Avatar" class="img-size-50 mr-3 img-circle">
                  <div class="media-body">
                    <h3 class="dropdown-item-title">
                      Brad Diesel
                      <span class="float-right text-sm text-danger"><i class="fas fa-star"></i></span>
                    </h3>
                    <p class="text-sm">Call me whenever you can...</p>
                    <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                  </div>
                </div>
                <!-- Message End -->
              </a>
              <div class="dropdown-divider"></div>
              <a href="#" class="dropdown-item">
                <!-- Message Start -->
                <div class="media">
                  <img src="/web/dist/img/user8-128x128.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3">
                  <div class="media-body">
                    <h3 class="dropdown-item-title">
                      John Pierce
                      <span class="float-right text-sm text-muted"><i class="fas fa-star"></i></span>
                    </h3>
                    <p class="text-sm">I got your message bro</p>
                    <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                  </div>
                </div>
                <!-- Message End -->
              </a>
              <div class="dropdown-divider"></div>
              <a href="#" class="dropdown-item">
                <!-- Message Start -->
                <div class="media">
                  <img src="/web/dist/img/user3-128x128.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3">
                  <div class="media-body">
                    <h3 class="dropdown-item-title">
                      Nora Silvester
                      <span class="float-right text-sm text-warning"><i class="fas fa-star"></i></span>
                    </h3>
                    <p class="text-sm">The subject goes here</p>
                    <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                  </div>
                </div>
                <!-- Message End -->
              </a>
              <div class="dropdown-divider"></div>
              <a href="#" class="dropdown-item dropdown-footer">See All Messages</a>
            </div>
          </li>
          <!-- Notifications Dropdown Menu -->
          <li class="nav-item dropdown">
            <a class="nav-link" data-toggle="dropdown" href="#">
              <i class="far fa-bell"></i>
              <span class="badge badge-warning navbar-badge">15</span>
            </a>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <span class="dropdown-header">15 Notifications</span>
              <div class="dropdown-divider"></div>
              <a href="#" class="dropdown-item">
                <i class="fas fa-envelope mr-2"></i> 4 new messages
                <span class="float-right text-muted text-sm">3 mins</span>
              </a>
              <div class="dropdown-divider"></div>
              <a href="#" class="dropdown-item">
                <i class="fas fa-users mr-2"></i> 8 friend requests
                <span class="float-right text-muted text-sm">12 hours</span>
              </a>
              <div class="dropdown-divider"></div>
              <a href="#" class="dropdown-item">
                <i class="fas fa-file mr-2"></i> 3 new reports
                <span class="float-right text-muted text-sm">2 days</span>
              </a>
              <div class="dropdown-divider"></div>
              <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-widget="fullscreen" href="#" role="button">
              <i class="fas fa-expand-arrows-alt"></i>
            </a>
          </li>
          <!-- site right top-->
          <!-- <li class="nav-item">
            <a class="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
              <i class="fas fa-th-large"></i>
            </a>
          </li> -->
          <!-- site right end -->
        </ul>
      </nav>
      <!-- /.navbar -->
    
      <!-- Main Sidebar Container -->
      <aside class="main-sidebar sidebar-dark-primary elevation-4">
        <!-- Brand Logo -->
        <a href="/" class="brand-link">
          <img src="/web/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
          <span class="brand-text font-weight-light">AdminLTE 3</span>
        </a>
    
        <!-- Sidebar -->
        <div class="sidebar">
          <!-- Sidebar user panel (optional) -->
          <div class="user-panel mt-3 pb-3 mb-3 d-flex">
            <div class="image">
              <img src="/web/dist/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image">
            </div>
            <div class="info">
              <a href="#" class="d-block">logged : {{$store.state.logged}}; level : {{$store.state.level}}</a>
            </div>
          </div>
    
          <!-- SidebarSearch Form -->
          <div class="form-inline">
            <div class="input-group" data-widget="sidebar-search">
              <input class="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search">
              <div class="input-group-append">
                <button class="btn btn-sidebar">
                  <i class="fas fa-search fa-fw"></i>
                </button>
              </div>
            </div>
          </div>
    
          <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="true">

              <template v-for="(x,i) in menu" >
                <li :key="i" v-if=" x.children==undefined && x.path!='/Login2' " class="nav-item">
                  <router-link @click.native="onClickLeaf" :to="x.path" class="nav-link">
                    <i class="nav-icon fas fa-th"></i>
                    <p>
                      {{x.label}}
                      <span class="right badge badge-danger">New</span>
                    </p>
                  </router-link>
                </li>
                <li :key="i" v-else-if=" x.path=='/Login2' && logged==0 " class="nav-item">
                  <router-link @click.native="onClickLeaf" :to="x.path" class="nav-link">
                    <i class="nav-icon fas fa-th"></i>
                    <p>
                      {{x.label}}
                      <span class="right badge badge-danger">New</span>
                    </p>
                  </router-link>
                </li>
                <li :key="i" v-else-if=" x.path=='/Login2' && logged==1 " class="nav-item">
                  <a @click="onLogout" href="javascript:void(0)" class="nav-link">
                    <i class="nav-icon fas fa-th"></i>
                    <p>
                      Logout
                      <span class="right badge badge-danger">New</span>
                    </p>
                  </a>
                </li>
                <li  :key="i" v-else class="nav-item">
                  <a href="#" class="nav-link active">
                    <i class="nav-icon fas fa-tachometer-alt"></i>
                    <p>
                      {{x.label}}
                      <i class="right fas fa-angle-left"></i>
                    </p>
                  </a>
                  <ul class="nav nav-treeview" v-show="sel.id==x.id" v-if="x.children!=undefined" >
                      <li class="nav-item" v-for="(y,j) in x.children" :key="j" >
                        <router-link @click.native="onClickLeaf" class="nav-link" :to="y.path">
                          <i class="far fa-circle nav-icon"></i>
                          <p>{{y.label}}</p>
                        </router-link>
                      </li>

                  </ul>
                </li>
              </template>
<!-- end left -->
            </ul>
          </nav>

          <!-- /.sidebar-menu -->
        </div>
        <!-- /.sidebar -->
      </aside>
    
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1 class="m-0">
                  
                  <!-- {{ $route.path }} {{ getLabelByPath($route.path) }} -->
                  {{ getLabelFromPath(menu,$route.path) }}

                </h1>
              </div>

              <!-- <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="#">Home</a></li>
                  <li class="breadcrumb-item active">Starter Page</li>
                </ol>
              </div> -->

            </div><!-- /.row -->
          </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
    
        <!-- Main content -->
        <div class="content">
          <div class="container-fluid">
            <div class="row">
    
              <!-- Begin view -->
              <div class="col-lg-12">
                <router-view></router-view>
              </div>
              <!-- end view -->
    
              <!-- /.col-md-6 -->
            </div>
            <!-- /.row -->
          </div><!-- /.container-fluid -->
        </div>
        <!-- /.content -->
      </div>
      <!-- /.content-wrapper -->
      <!-- Control Sidebar -->
      <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
        <div class="p-3">
          <h5>Title</h5>
          <p>Sidebar content</p>
        </div>
      </aside>
      <!-- /.control-sidebar -->
    
      <!-- Main Footer -->
      <footer class="main-footer">
        <!-- To the right -->
        <div class="float-right d-none d-sm-inline">
          Anything you want
        </div>
        <!-- Default to the left -->
        <strong>Copyright &copy; 2014-2021 <a href="https://adminlte.io">AdminLTE.io</a>.</strong> All rights reserved.
      </footer>

    </div>
    
  </div>
</template>
<script>
import HelloWorld from '@/components/HelloWorld.vue'
import Tree from '@/layout/Tree.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    Tree
  },
  computed: {
    logged:function(){
      return this.$store.state.logged;
    }
  },
  data () {
    return {
      menu: [],
      msg:'hello world',
      sel:{},

    }
  },
  mounted () {
    let routes = JSON.parse(JSON.stringify(this.$store.state.routes))
    this.init(routes,this.$store.state.level);
    this.menu=routes[0].children;

    //console.log( this.getLabelFromPath(this.menu,this.$route.path) );
  },
  methods: {

    onLogout() {
      localStorage.removeItem("level");
      localStorage.removeItem("token");
      localStorage.removeItem("Username");
      localStorage.removeItem("Role");
      location.href = "/";
    },
    onClickLeaf(data){
      if( $(window).width() < 500 ){
        $('[data-widget="pushmenu"]').PushMenu("toggle");        
      }
    },    
    onClick(x){
      if(this.sel.id==undefined){
        this.sel=x;
      }else if(this.sel.id==x.id){
        this.sel={}
      }else{
        this.sel=x;
      }
    },


    init (menu,level) {
      //gan vao pid
      let s = []
      menu.forEach(x => {
        this.$set(x, 'pid', 0)
        this.$delete(x, 'component')
        s.push(x)
      })
      let thoat = false
      let layra
      while (s.length != 0 && thoat == false) {
        layra = s.pop()
        let path = layra.path
        if( layra.meta.auth <= level ){

          //Begin if
          if (layra.children != undefined) {
            layra.children.forEach(x => {
              this.$delete(x, 'component')
              this.$set(x, 'pid', layra['id'])

              if (path == '/') {
                if (x.path == '') {
                  x.path = path
                } else {
                  x.path = path + x.path
                }
              } else {
                if (x.path == '') {
                  x.path = path
                } else {
                  x.path = path + '/' + x.path
                }
              }

              s.push(x)
            })
          }
          else if (layra.path == '*') {
            this.del( menu,layra.id);
          }
          else if( layra.path.split(':').length == 2 ){
            this.del( menu,layra.id);
          }
          //End if

        }else{
          this.del(menu,layra.id);
        }


      }
    },
    
    find (menu, id) {
      let s = []
      menu.forEach(x => {
        s.push(x)
      })
      let thoat = false
      let layra
      while (s.length != 0 && thoat == false) {
        layra = s.pop()
        if (layra.id == id) {
          thoat = true
        } else {
          if (layra.children != undefined) {
            layra.children.forEach(x => {
              s.push(x)
            })
          }
        }
      }
      if (thoat == false) {
        return -1
      } else {
        return layra
      }
    },

    del (menu, id) {
      let obj = this.find(menu, id)
      let pid = obj.pid
      let parent = this.find(menu, pid)
      if (parent != -1) {
        let index = parent.children.findIndex(x => x.id == id)
        if (index >= 0) {
          parent.children.splice(index, 1)
          if(parent.children.length==0){
            this.$delete(parent,'children');
          }
        } else {
          alert('ko xoa duoc')
        }
      } else {
        let index = menu.findIndex(x => x.id == id)
        if (index >= 0) {
          menu.splice(index, 1)
        }
      }
    },
    getLabelFromPath( menu,path ){
      let obj=this.findLabelFromPath(menu,path);
      if(obj.children==undefined){
        return obj.label;
      }else{
        return obj.children[0].label;
      }
      
    },
    findLabelFromPath( menu,path ){
      let s = []
      menu.forEach(x => {
        s.push(x)
      })
      let thoat = false
      let layra
      while (s.length != 0 && thoat == false) {
        layra = s.pop()
        if ( layra.path == path ) {
          thoat = true
        } else {
          if (layra.children != undefined) {
            layra.children.forEach(x => {
              s.push(x)
            })
          }
        }
      }
      if (thoat == false) {
        return -1
      } else {
        return layra
      }
    },



    getRouteMenuLevel (routes, level) {
      let s = []
      let kq = []
      routes.forEach(x => {
        if (x.path != '*') {
          x['pid'] = 0
          x['level'] = 0
          x.show = 1
          x.component = null
          s.push(x)
        } else {
          x.show = 0
        }
      })
      while (s.length != 0) {
        let layra = s.pop()
        let path = layra.path
        if (layra.children != undefined) {
          let ii = -1
          layra.children.forEach((y, i) => {
            if (y.path != '' && path != '/') {
              y.path = path + '/' + y.path
            } else {
              y.path = path + y.path
            }
            y['level'] = layra.level + 1
            y['pid'] = layra.id
            y.component = null
            y.show = 1
            /* loai dau : */
            if (y.path.split(':').length == 1) {
              s.push(y)
            } else {
              //console.log(y.id,i);
              ii = i
            }
          })
          if (ii != -1) layra.children.splice(ii, 1)
        }
      }
      return routes
    },
    getRouteMenu (routes) {
      let s = []
      let kq = []
      routes.forEach(x => {
        if (x.path != '*') {
          x['pid'] = 0
          x['level'] = 0
          x.show = 1
          x.component = null
          s.push(x)
        } else {
          x.show = 0
        }
      })
      while (s.length != 0) {
        let layra = s.pop()
        let path = layra.path
        if (layra.children != undefined) {
          let ii = -1
          layra.children.forEach((y, i) => {
            if (y.path != '' && path != '/') {
              y.path = path + '/' + y.path
            } else {
              y.path = path + y.path
            }
            y['level'] = layra.level + 1
            y['pid'] = layra.id
            y.component = null
            y.show = 1
            /* loai dau : */
            if (y.path.split(':').length == 1) {
              s.push(y)
            } else {
              //console.log(y.id,i);
              ii = i
            }
          })
          if (ii != -1) layra.children.splice(ii, 1)
        }
      }
      return routes
    },
    getLabelById(id){
      let list=this.getListLink();
      let index=list.findIndex(x=>x.id==id);
      let kq='';
      if( index >= 0 ){
        kq=list[index].label;
      }
      return kq;
    },
    getLabelByPath(path){
      let list=this.getListLink();
      let index=list.findIndex(x=>x.path==path);
      let kq='';
      if( index >= 0 ){
        kq=list[index].label;
      }
      return kq;
    },
    getListLink () {
      let s = []
      let kq = []
      //let routes = JSON.parse(JSON.stringify(this.routes));
      let routes = JSON.parse(JSON.stringify(this.$store.state.routes))
      routes.forEach(x => {
        if (x.path != '*') {
          s.push(x)
        }
      })
      while (s.length != 0) {
        let layra = s.pop()
        //console.log(layra);
        if (layra.children == undefined) {
          kq.push(layra)
        } 
        let path = layra.path
        if (layra.children != undefined) {
          layra.children.forEach(y => {
            if (y.path != '' && path != '/') {
              y.path = path + '/' + y.path
            } else {
              y.path = path + y.path
            }
            //console.log(y.show)
            s.push(y)
          })
        }
      }
      //console.log( JSON.stringify(kq));
      kq.sort((a, b) => a.id - b.id)
      return kq
    },

  }
}
</script>
<style scoped>
  #my-menu {
    cursor: pointer;
    background-color: aqua;
  }
</style>
