<template>
  <li>
      <div @click="toggle" >
        <template v-if="isFolder"  >
          <b>{{ item.label }}</b>
          <span> [{{ isOpen ? "-" : "+" }}]</span>
        </template>
        <template v-else>
          <router-link class="link" :to="item.path" @click.native="$emit('click-leaf',item.label)">
            {{item.label}} 
            <!-- {{item.meta.auth}} -->
          </router-link>
        </template>
      </div>
      <ul v-show="!isOpen">
          <Tree v-for="(x,i) in item.children" :key="i" :item="x" @click-leaf="$emit('click-leaf',x.label)" >
          </Tree>
      </ul>
  </li>
</template>
<script>
import HelloWorld from '@/components/HelloWorld.vue'
export default {
  name: 'Tree',
  props: {
    item: Object,
  },
  components: {
    HelloWorld
  },
  computed: {
    isFolder: function () {
      return this.item.children && this.item.children.length;
    }
  },  
  data () {
    return {
      isOpen: [true,false][0],
    }
  },
  methods:{
    myalert(){
      alert('hello');
    },
    toggle: function () {
      if (this.isFolder) {
        this.isOpen = !this.isOpen;
      }
    },
    isLeaf(){
      let kq=0;
      if(this.item.children === undefined  ){//if undefined js
        kq=1;
      }
      return kq;
    },
  },
}
</script>
<style scoped>
  a.link.active{
    background-color: blue;
    color:white;
  }
  a:hover{
    background-color: yellow;
  }
  li,
  ul{
    list-style-type: none;
  }
  li a{
    display: block;
  }
/*
  a.link.active{
    background-color: blue;
    color:white;
  }
  a{
    padding-left: 5px;
    padding-right: 5px;
    display:block;
    text-decoration: none;
  }
  li{
    list-style-type: none;
  }
  a:hover{
    background-color: green;
    color: white;
  }
  ul{
    padding-left: 20px; 
  } 
*/   
</style>

