import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import Product from '../views/Product.vue'

import LoginView from '../views/LoginView.vue'
import Login2 from '../views/Login2.vue'

import LayoutMain from '../layout/LayoutMain.vue'
import LayoutSub from '../layout/LayoutSub.vue'

Vue.use(VueRouter)

var i = 1;
function getId() {
  let kq = i;
  i += 1;
  return kq;
}

const routes = [
  {
    id: getId(), path: '/', label: 'DashBoard', meta: { auth: 0 }, component: LayoutMain, children: [
      { id: getId(), path: '', label: 'Trang chủ', meta: { auth: 0 }, component: HomeView },
      { id: getId(), path: 'about', label: 'About', meta: { auth: 0 }, component: AboutView },
      { id: getId(), path: 'product', label: 'Product', meta: { auth: 9 }, component: Product },
      {
        id: getId(), label: 'Môn Học', path: 'MonHoc', meta: { auth: 9 },
        component: LayoutSub, children: [
          { id: getId(), label: 'Môn Toán', path: '', component: () => import('@/views/MonHoc/Toan.vue'), meta: { auth: 9 } },
          { id: getId(), label: 'Môn Lý', path: 'Ly', component: () => import('@/views/MonHoc/Ly.vue'), meta: { auth: 9 } },
          { id: getId(), label: 'Môn Hóa', path: 'Hoa', component: () => import('@/views/MonHoc/Hoa.vue'), meta: { auth: 9 } },
          { id: getId(), path: 'List', label: "List", component: () => import('@/views/MonHoc/List.vue'), meta: { auth: 9 } },
          { id: getId(), path: 'Edit/:id', label: "Edit", component: () => import('@/views/MonHoc/Edit.vue'), show: 0, meta: { auth: 9 } },//<----Đừng hiện lên
        ]
      },
      {
        id: getId(), label: 'TestLeftMenu', path: 'TestLeftMenu', meta: { auth: 0 },
        component: LayoutSub, children: [
          { id: getId(), label: 'Basic', path: '', component: () => import('@/views/TestLeftMenu/Basic.vue'), meta: { auth: 0 } },
        ]
      },
      { id: getId(), path: 'BaiViet/:id', label: 'BaiViet', meta: { auth: 9 }, component: null },
      { id: getId(), path: 'Test', label: 'Test', meta: { auth: 9 }, component: () => import('@/views/Test.vue') },
      { id: getId(), path: 'Tpl', label: 'Tpl', meta: { auth: 9 }, component: () => import('@/views/Tpl.vue') },
      

      { id: getId(), path: 'TestObjDel2', label: 'TestObjDel2', meta: { auth: 9 }, component: () => import('@/views/TestObjDel2.vue') },
      { id: getId(), path: 'Login2', label: 'Login2', meta: { auth: 0 }, component: Login2 },
    ]
  },
  { id: getId(), path: '/BaiViet2/:id', label: 'BaiViet2', meta: { auth: 9 }, component: () => import('@/views/BaiViet2.vue') },
  { id: getId(), path: '/login333', label: 'Login333', meta: { auth: 0 }, component: LoginView },
  { id: getId(), path: '*', label: 'hihi', meta: { auth: 0 }, redirect: '/' }
]

import store from '../store'
store.state.routes = routes;

const router = new VueRouter({
  mode: ['hash','history'][1],//'hash',//'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: "active",
  routes
})
/*
 <router-link class="link" to="/">DashBoard</router-link><br>
a.link.active{
  background-color: blue;
  color:white;
}
*/

let level = localStorage.getItem("level") == null ? 0 : localStorage.getItem("level");
//console.log(level);
let logged = (level == 0) ? 0 : 1;
store.state.logged=logged;
store.state.level=level;
router.beforeEach((to, from, next) =>
{
  let auth = to.meta.auth;
  console.log({
    auth:auth,
    level:level
  });
  /*
  if (level >= auth) {
    next();
  } else {
    if (to.path == '/Login') {

    } else {
      next('/Login');
    }
  }
  */
  next();


});


export default router
