<template>
    <div class="m-4" :style="{ 'text-align':'right' }">
        <h4>Login2</h4>
        UserName : <input class="m-1" v-model="r.username" /><br>
        Password : <input class="m-1" v-model="r.password" /><br>
        <b-button class="mr-1" size="sm" @click="onLogin" >Login</b-button>
        <b-button size="sm" @click="$router.push('/')" >Cancel</b-button><br>
    </div>
</template>
<script>
import store from '@/store';
import fetchClient from "@/fetchClient";
import XuLy from "@/XuLy";
import moment from "moment";
moment.locale("es");
export default {
  name: 'Login',
  data()
  {
    return {
      msg:'Hello world',
      r:{
        username:'',//thuc101
        password:''//
      },
      users:[
        {id:1,username:'admin',password:'1',level:9},
        {id:2,username:'manager',password:'1',level:7},
        {id:3,username:'user',password:'1',level:1}
      ]
    }
  },
  methods:{
    getUser(username,password){
      let index=this.users.findIndex(x=>x.username==username && x.password==password);
      if(index < 0){
        return -1;
      }
      return this.users[index];
    },
    onLogin(){
      let r = {
        Username: this.r.username,//this.r.Username,
        Password: this.r.password// this.r.Password,
      };
      this.Login(r);
    },
    onCancel(){
      location.href="/";
    },    
    OnLogout() {
      localStorage.removeItem("logged");
      location.reload();
    },
    isLogged() {
      let logged = localStorage.getItem("logged") == null ? 0 : 1;
      return logged;
    },
    async Login(r) {
      let that=this;
      return await fetchClient
        .post(`/api/NguoiDung/Authenticate`, r)
        .then((res) => {
          //alert(JSON.stringify(res));
          // {"data":{"Id":1,"FirstName":"Thức","LastName":"Nguyễn Tấn","Username":"admin","Password":null,"Role":"Admin,Manager"
          let token = res.data.Token;
          localStorage.setItem("token", token);
          localStorage.setItem("Username", res.data.Username);
          localStorage.setItem("Role", res.data.Role);
          that.GetLevel();         
        })
        .catch((err) => {
          XuLy.XuLyErr(err);
        });
    },
    async GetLevel() {
      return await fetchClient
        .get(`/api/NguoiDung/GetLevel`)
        .then((res) => {
            //alert(JSON.stringify(res));
          let level=res.data;
          localStorage.setItem("level", level);
          location.href="/";
        })
        .catch((err) => {
          XuLy.XuLyErr(err);
        });
    },
  }
}
</script>
