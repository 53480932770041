<template>
  <b-row>
    <b-col>
        <div class="m-1" :style="{ 'float':'right' }">
          <button class="btn btn-outline-secondary btn-sm" @click=" ()=>{this.show=!this.show} " :disabled="false">{{(show==false) ? 'Show code' : 'Hide' }}</button>
        </div>
        <p v-show=" show ">
          <editor v-model="mytxt" @init="editorInit" lang="html" theme="chrome" width="100%" height="800"></editor>
        </p>
    </b-col>
  </b-row>
</template>
<script>
export default {
  props:["mytxt"],
  name: 'Tpl',
  components:{
    editor: require('vue2-ace-editor'),
  },  
  data(){
    return{
      show:false,
    }
  },
  mounted(){
  },
  methods: {
      editorInit: function (editor) {
          require('brace/ext/searchbox')
          require('brace/ext/language_tools') //language extension prerequsite...
          require('brace/mode/html')                
          require('brace/mode/javascript')    //language
          require('brace/mode/less')
          require('brace/theme/chrome')
          require('brace/snippets/javascript') //snippet
          editor.setOptions({
            fontFamily: "'Courier New', Courier, monospace",
            fontSize: "11pt"
          });
      }
  },
}
</script>
<style scoped>
  .my-textarea{
    font-family: 'Courier New', Courier, monospace;
    font-size: 1rem;
  }
</style>