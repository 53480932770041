<template>
  <div class="home">
    <h4>Home</h4>
    <div>This is an Home page</div>

    <div class="h2 mb-0">
      <b-icon-arrow-up></b-icon-arrow-up>
      <b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill>
    </div>

    <div>
      <b-icon icon="camera" font-scale="0.5"></b-icon>
      <b-icon icon="camera" font-scale="1"></b-icon>
      <b-icon icon="camera" font-scale="2"></b-icon>
      <b-icon icon="camera" font-scale="3"></b-icon>
      <b-icon icon="camera" font-scale="4"></b-icon>
      <b-icon icon="camera" font-scale="5"></b-icon>
      <b-icon icon="camera" font-scale="7.5"></b-icon>
      <b-icon icon="exclamation-triangle-fill" font-scale="7.5"></b-icon>
    </div>
    <ul>
      <li><router-link to="/BaiViet2/1" >Bai viet 1</router-link></li>
      <li><router-link to="/BaiViet2/2" >Bai viet 2</router-link></li>
      <li><router-link to="/BaiViet2/3" >Bai viet 3</router-link></li>
      <li><router-link to="/BaiViet2/4" >Bai viet 4</router-link></li>
    </ul>
    


  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>
