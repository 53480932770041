import axios from 'axios';

const fetchClient = () => {
  const defaultOptions = {
    baseURL: '',
    method: 'get',
    headers: {
      //'Content-Type': 'application/json',
      'Content-Type': 'application/json;charset=utf-8'
    },
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
  });

  return instance;
};

export default fetchClient();